import { ImagePreview, showDialog } from 'vant';
import { mapState } from "vuex";
import Rules from "./drawRules.vue";
import { env } from 'md-base-tools/env';
import { toRaw } from 'vue';
import { mdToast } from '../../../utils/tools';
export default {
  components: {
    Rules
  },
  props: {
    activity_info: {
      type: Object
    },
    updateInfo: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId,
      linkUrl: state => state.user.linkUrl
    }),
    ruleArr() {
      let ruleArr = [];
      if (this.updateInfo.type == 2) {
        if (this.activity_info.rule_one) {
          ruleArr.push({
            title: '关注项目发起者',
            label: ''
          });
        }
        if (this.activity_info.rule_two) {
          ruleArr.push({
            title: '分享项目',
            label: '分享'
          });
        }
        if (this.activity_info.reward_name || Number(this.activity_info.full_money) > 0) {
          ruleArr.push({
            title: '支持/购买项目',
            label: this.activity_info.reward_name
          });
        }
        if (this.activity_info.rule_five && this.activity_info.rule_five.topic) {
          ruleArr.push({
            title: '带话题参与项目讨论',
            label: this.activity_info.rule_five.topic
          });
        }
      } else if (this.updateInfo.type == 3) {
        if (this.activity_info.rule_one) {
          ruleArr.push({
            title: '关注项目发起者',
            label: ''
          });
        }
        if (this.activity_info.rule_four) {
          ruleArr.push({
            title: '看好项目',
            label: ''
          });
        }
        if (this.activity_info.rule_two) {
          ruleArr.push({
            title: '分享项目',
            label: this.activity_info.rule_two
          });
        }
        if (this.activity_info.reward_name) {
          ruleArr.push({
            title: '付费支持本项目的任一回报档：',
            label: this.activity_info.reward_name
          });
        }
        if (this.activity_info.more_explain && this.activity_info.more_explain.length > 0) {
          for (let i = 0; i < this.activity_info.more_explain.length; i++) {
            ruleArr.push({
              title: this.activity_info.more_explain[i],
              label: ''
            });
          }
        }
      }
      return ruleArr;
    },
    isPhone() {
      return env.isModianIos() || env.isModianAndroid();
    },
    isIOS() {
      return env.isModianIos();
    },
    imgs() {
      return this.activity_info.img;
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    showRule() {
      this.show = true;
    },
    // 查看抽奖结果 -- 普通抽奖
    /* eslint-disable */
    showDrawResult() {
      if (this.updateInfo.activity_info.if_success == 0) {
        mdToast({
          message: '项目发起者还没开奖哦，请耐心等待开奖结果',
          duration: '3000',
          className: 'toast-btn-class'
        });
      } else {
        const active_id = this.updateInfo.prize_info[0].active_id;
        graft.app({
          functionName: "openController",
          functionParams: `md://prizeDraw?id=${active_id}`,
          webFun: e => {
            showDialog({
              message: '您可打开「摩点」App，查看开奖信息',
              confirmButtonText: '前往摩点',
              confirmButtonColor: '#00cba6'
            }).then(() => {
              location.href = this.linkUrl;
            });
          }
        });
      }
    },
    // 参与抽奖  -- 助力抽奖
    showResult() {
      const active_id = this.updateInfo.prize_info[0].active_id;
      graft.app({
        functionName: "openController",
        functionParams: `md://helpPrizeDraw?id=${active_id}`,
        webFun: e => {
          showDialog({
            message: this.updateInfo.activity_info.if_success == 2 ? '您可打开「摩点」App，查看开奖信息' : '您可打开「摩点」App，参与抽选',
            confirmButtonText: '前往摩点',
            confirmButtonColor: '#00cba6'
          }).then(() => {
            location.href = this.linkUrl;
          });
        }
      });
    },
    getText(text, matched_item) {
      var str = text;
      if (matched_item) {
        const arr = matched_item.split(',');
        arr.map(item => {
          var reg = new RegExp(item, "g");
          str = str.replace(reg, '<span style="color: #00C4A1;">' + item + '</span>');
        });
      }
      return str;
    },
    showBigImg(index) {
      const _this = this;
      graft.app({
        functionName: "onImageClicked",
        functionParams: {
          imageUrls: toRaw(_this.imgs),
          imageIndex: index
        },
        webFun: e => {
          ImagePreview({
            images: this.imgs,
            startPosition: index
          });
        }
      });
    }
  }
};