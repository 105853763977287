import MDRouter from "@/service/router";
import imgUrlFilter from 'md-base-tools/imgUrlFilter';
import cont_match_reg from "@/utils/editor.js";
import { getRednoteInfo } from '@/api/index';
import { domPixelRatio } from 'md-base-tools/env';
import { mapState } from "vuex";
import redirect from "@/service/redirect";
export default {
  props: {
    potatoId: {
      type: String
    }
  },
  watch: {
    "potatoId": {
      handler: function (val) {
        if (val) {
          this.getPotatoInfo();
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState({
      ifLogin: state => state.user.ifLogin // 是否登录
    })
  },
  data() {
    return {
      radio: parseInt(domPixelRatio()) || 1,
      qrcodeVisible: false,
      qrcodeList: [],
      potatoObj: {}
    };
  },
  methods: {
    getPotatoInfo() {
      getRednoteInfo({
        rednote_draw_id: this.potatoId
      }).then(res => {
        if (res.status == 0) {
          this.potatoObj = res.data;
          this.$refs.describe.appendChild(cont_match_reg(res.data.describe ? res.data.describe.replace(/\n/g, "<br>") : ''));
        }
      });
    },
    imgUrlFilter() {
      return imgUrlFilter(...arguments);
    },
    potatoBtnClick(type) {
      // 如果未登录，跳转登录页
      if (!this.ifLogin) {
        return redirect.to('login');
      }
      switch (type) {
        // 提交凭证
        case 1:
          MDRouter.goRednoteUpload(this.potatoId);
          break;
        case 2:
          MDRouter.goRednoteUpload(this.potatoId);
          break;
        // 中奖名单
        case 4:
          MDRouter.goRednotePrize(this.potatoId);
          break;
        default:
          break;
      }
    }
  }
};